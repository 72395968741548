import React from 'react';
import './header.css';

const Header = () => {
  return (
    <header className="form-header">
        <img src="/clllogo.png" alt="Logo" className="logo" /> 
    </header>  
  );
};

export default Header;