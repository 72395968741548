import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './rejectionReasons.css';

const RejectionReasons = () => {
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  const [reasons, setReasons] = useState([]);
  const [newReason, setNewReason] = useState('');
  const [selectedReason, setSelectedReason] = useState(null); // For delete modal

  useEffect(() => {
    fetchRejectionReasons();
  }, []);

  const fetchRejectionReasons = async () => {
    try {
      const response = await axios.get(`${axiosDomain}/api/rejection-reasons`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
      });
      setReasons(response.data.reasons);
    } catch (error) {
      console.error("Error fetching rejection reasons:", error);
    }
  };

  const handleAddReason = async () => {
    if (newReason.trim() === '') return;
    
    try {
      await axios.post(`${axiosDomain}/api/add-rejection-reason`, { reason: newReason }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
      });
      setNewReason('');
      fetchRejectionReasons(); // Refresh the reasons list after adding
      alert('Reason added');
    } catch (error) {
      console.error("Error adding rejection reason:", error);
    }
  };

  const handleDeleteReason = async () => {
    if (!selectedReason) return;

    try {
      await axios.post(`${axiosDomain}/api/delete-rejection-reason`, { reason_id: selectedReason }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
      });
      setSelectedReason(null);
      fetchRejectionReasons(); // Refresh the reasons list after deletion
      alert('Reason deleted');
    } catch (error) {
      console.error("Error deleting rejection reason:", error);
    }
  };

  return (
    <div className="rejection-reasons-container">
      <h2>Manage Rejection Reasons</h2>

      <div className="add-reason-section">
        <input 
          type="text" 
          value={newReason} 
          onChange={(e) => setNewReason(e.target.value)} 
          placeholder="Enter new reason" 
        />
        <button onClick={handleAddReason}>Add Reason</button>
      </div>

      <div className="reasons-list">
        <h3>Existing Rejection Reasons</h3>
        {reasons.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Reason</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {reasons.map((reason, idx) => (
                <tr key={reason.id}>
                  <td>{reason.reason}</td>
                  <td>
                    <button className="delete-btn" onClick={() => setSelectedReason(reason.id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No rejection reasons available.</p>
        )}
      </div>

      {/* Modal for delete confirmation */}
      {selectedReason && (
        <div className="modal">
          <div className="modal-content">
            <h4>Are you sure you want to delete this reason?</h4>
            <div className="modal-actions">
              <button className="confirm-btn" onClick={handleDeleteReason}>Confirm</button>
              <button className="cancel-btn" onClick={() => setSelectedReason(null)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RejectionReasons;
