// src/components/ResubmissionForm.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './ResubmissionForm.css';
import { useLocation, useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import PdfEditor from './PdfEditor';

const ResubmissionForm = ({ userData }) => {
  const [rejectedDocuments, setRejectedDocuments] = useState([]);
  const [resubmittedDocuments, setResubmittedDocuments] = useState({});
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [hasLOA, setHasLOA] = useState(false);
    
  const [loaFormData, setLoaFormData] = useState({
    title: '',
    first_name: '',
    last_name: '',
    date_of_birth: '',
    current_address_line_1: '',
    current_address_line_2: '',
    current_address_line_3: '',
    current_address_line_4: '',
    current_post_code: '',
    previous_names: '',
    credit_agreement_number: '',
    lender: '',
    addcases: [],
    ip: '', 
    previousadds: [],
    addlenders: [],
    addcredits: [],
    matter_type: ''
  });

  // States for Previous Addresses and Names
  const [prevAddresses, setPrevAddresses] = useState([]);
  const [prevAdd, setPrevAdd] = useState({
    previous_address_line_1: '',
    previous_address_line_2: '',
    previous_address_line_3: '',
    previous_address_line_4: '',
    previous_post_code: ''
  });
  const [prevNames, setPrevNames] = useState([]);
  const [prevName, setPrevName] = useState('');

  // States for PDF Generation
  const [pdfBytes, setPdfBytes] = useState(null);
  const [triggerPreview, setTriggerPreview] = useState(false);

  // Signature Pad Reference
  const sigCanvas = useRef(null);
  const [signatureData, setSignatureData] = useState(null);
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('tk');

  // State for Other Documents
  const [otherDocument, setOtherDocument] = useState(null);
  const [otherSelected, setOtherSelected] = useState(false);
  const [otherName, setOtherName] = useState(null);

  const [otherDocumentType, setOtherDocumentType] = useState('');

  useEffect(() => {
    if (userData && userData.rejectedDocuments) {
      setRejectedDocuments(userData.rejectedDocuments);
      const containsLOA = userData.rejectedDocuments.some(doc => doc.doctype === 'LOA');
      setHasLOA(containsLOA);

      // Initialize LOA Form Data
      setLoaFormData({
        title: userData.title || '',
        first_name: userData.first_name || '',
        last_name: userData.last_name || '',
        date_of_birth: userData.date_of_birth || '',
        current_address_line_1: userData.current_address_line_1 || '',
        current_address_line_2: userData.current_address_line_2 || '',
        current_address_line_3: userData.current_address_line_3 || '',
        current_address_line_4: userData.current_address_line_4 || '',
        current_post_code: userData.current_post_code || '',
        previous_names: userData.previous_names || '',
        credit_agreement_number: userData.credit_agreement_number || '',
        lender: userData.lender || '',
        addcases: userData.addcases || [],
        ip: userData.ip || '',
        previousadds: userData.previousadds || [],
        addlenders: userData.addlenders || [],
        addcredits: userData.addcredits || [],
        matter_type: userData.matter_type || ''
      });
    }
  }, [userData]);

  // Handlers for LOA Form Fields
  const handleLoaChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('credit_agreement_number')) {        
      const nameIndex = name.replace('credit_agreement_number', '')                
      const creditIndex = Number(nameIndex);
      const newCredits = [...loaFormData.addcredits];
      newCredits[creditIndex].credit_agreement_number = value;
      setLoaFormData({
        ...loaFormData,
        addcredits: newCredits
      });
    } else {
      setLoaFormData({
        ...loaFormData,
        [name]: value
      });
    }
  };

  // Handlers for Previous Addresses
  const handlePrevAddChange = (e) => {
    const { name, value } = e.target;
    setPrevAdd({
      ...prevAdd,
      [name]: value
    });
  };

  const addPreviousAddress = () => {    
    const newAdd = { ...prevAdd };
    setPrevAddresses([...prevAddresses, newAdd]);
    setPrevAdd({
      previous_address_line_1: '',
      previous_address_line_2: '',
      previous_address_line_3: '',
      previous_address_line_4: '',
      previous_post_code: ''
    });
    setLoaFormData({
      ...loaFormData,
      previousadds: [...loaFormData.previousadds, newAdd]
    });
  };

  const removePreviousAddress = (index) => {    
    const newAddresses = [...prevAddresses];
    newAddresses.splice(index, 1);
    setPrevAddresses(newAddresses);
    setLoaFormData({
      ...loaFormData,
      previousadds: newAddresses
    });
  };

  // Handlers for Previous Names
  const handlePrevNameChange = (e) => {
    setPrevName(e.target.value);
  };

  const addPreviousName = () => {
    if (prevName.trim() === '') return;
    setPrevNames([...prevNames, prevName.trim()]);
    setLoaFormData({
      ...loaFormData,
      previous_names: [...prevNames, prevName.trim()].join(',')
    });    
    setPrevName('');
  };

  const removePreviousName = (index) => {
    const newNames = [...prevNames];
    newNames.splice(index, 1);
    setPrevNames(newNames);
    setLoaFormData({
      ...loaFormData,
      previous_names: newNames.join(',')
    });
  };

  // Handler for Resubmitted Documents
  const handleDocumentChange = (e, index, documentType) => {
    const file = e.target.files[0];
    if (file) {
      // Rename the file to match the naming convention: `${documentType}${extension}`
      const extension = file.name.slice(file.name.lastIndexOf('.'));
      const newFilename = `${documentType}${extension}`;
      const renamedFile = new File([file], newFilename, { type: file.type });
      setResubmittedDocuments((prevDocs) => ({
        ...prevDocs,
        [index]: {
          documentType,
          file: renamedFile,
        },
      }));
    }
  };

  // Handlers for Other Documents
  const handleOtherDocumentTypeChange = (e) => {
    const { value } = e.target;        
    if (value === 'Other') {   
      setOtherSelected(true);   
      setOtherName(value);
    } else {      
      setOtherSelected(false);
      setOtherDocumentType(e.target.value);    
    }
  };

  const handleOtherDocumentFileChange = (e) => {
    const file = e.target.files[0];    
    if (file) {            
      setOtherDocument({file, name: file.name });      
    }     
  };  

  // Handler for Terms Checkbox
  const handleCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  // Handler for Signature Clear
  const clearSignature = () => {
    sigCanvas.current.clear();    
    setSignatureData(null);
  };

  // Handler for PDF Generation
  
  const handleSignatureEnd = () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const dataUrl = sigCanvas.current.getCanvas().toDataURL('image/png');
      setSignatureData(dataUrl);
    } else {
      setSignatureData(null);
    }
  };
  // Handler for Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    

    // Reset messages
    setMessage('');
    setError('');

    // Validation
    if (!termsAccepted) {
      setError('Please confirm that all information provided is true.');
      return;
    }

    //if (Object.keys(resubmittedDocuments).length === 0 && !otherDocument) {
//      setError('Please select at least one document to upload.');
  //    return;
    //}

    if (hasLOA) {
      // Validate LOA Form Fields
      const requiredFields = ['first_name', 'last_name', 'date_of_birth', 'current_address_line_1', 'current_post_code'];
      for (let field of requiredFields) {
        if (!loaFormData[field].trim()) {
          setError(`Please fill out the ${field.replace('_', ' ')} field.`);
          return;
        }
      }

      if (sigCanvas.current.isEmpty()) {
        setError('Please provide a signature.');
        return;
      }
    }

    if (otherDocument) {
      if (!otherDocumentType) {        
        setError('Please select a document type before uploading.');
        return;
      }
      if (otherSelected && !otherName) {
        setError('Please provide a document name.');
        return;
      }
    }

    setIsLoading(true);

    const submissionData = new FormData();
    submissionData.append('caseId', userData.case_id);
    for (const key in loaFormData) {
      if (loaFormData.hasOwnProperty(key) && key !== 'documents') {
        if (typeof loaFormData[key] === 'object') {
          submissionData.append(key, JSON.stringify(loaFormData[key]));
        } else {
          submissionData.append(key, loaFormData[key]);     
        }        
      }
    }      

    const documentTypesArray = [];

    // Append Re-uploaded Rejected Documents
    Object.entries(resubmittedDocuments).forEach(([index, docInfo]) => {
      submissionData.append('documents', docInfo.file, docInfo.file.name);
      submissionData.append('documentType', docInfo.documentType);
      documentTypesArray.push(docInfo.documentType);
    });

    // Append Other Documents
    // otherDocuments.forEach((doc, index) => {
    //   submissionData.append('documents', doc.file, doc.file.name);
    //   submissionData.append('documentType', doc.type);
    //   documentTypesArray.push(doc.documentType);
    // });
    if (otherDocument) {      
      const originalName = otherDocument.file.name;
      const extension = originalName.slice(originalName.lastIndexOf('.'));
      const newFilename = `${otherSelected ? otherName : otherDocumentType}${extension}`;      
      submissionData.append('documents', otherDocument.file, newFilename);
      //submissionData.append('documents', otherDocument.file, otherDocument.file.name);
      if (otherSelected) {
        submissionData.append('documentType', otherName);
        documentTypesArray.push(otherName);  
      } else {
        submissionData.append('documentType', otherDocumentType);
        documentTypesArray.push(otherDocumentType);
      }
    }

    
    
    submissionData.append('documentTypes', JSON.stringify(documentTypesArray));
    if (hasLOA && pdfBytes) {
      // Append Generated PDFs
      for (let i = 0; i < pdfBytes.loaPDFs.length; i++) {
        submissionData.append('LOA', new Blob([pdfBytes.loaPDFs[i].data], { type: 'application/pdf' }), `${pdfBytes.loaPDFs[i].caseRef}_${loaFormData.last_name}_${loaFormData.addlenders[i].lender}_LOA.pdf`); //${loaFormData.lender}_LOA.pdf`);
        submissionData.append('COA', new Blob([pdfBytes.coaPDFs[i].data], { type: 'application/pdf' }), `${pdfBytes.coaPDFs[i].caseRef}_${loaFormData.last_name}_${loaFormData.addlenders[i].lender}_COA.pdf`); //${loaFormData.lender}_COA.pdf`);        
      }
      
      for (let i = 0; i < pdfBytes.appendixPDFs.length; i++) {
        if (pdfBytes.appendixPDFs && pdfBytes.appendixPDFs.length > 0) {
          submissionData.append('Appendix', new Blob([pdfBytes.appendixPDFs[i].data], { type: 'application/pdf' }), `${loaFormData.last_name}_${loaFormData.lender}_Appendix.pdf`);
        }
      }            
    }

    // Handle signature if LOA is present
    // if (hasLOA) {                  
    //   sigCanvas.current.getTrimmedCanvas().toBlob(async (blob) => {
    //     submissionData.append('signature', blob, 'signature.png');
    //   }, 'image/png');
    // }

    try {      
      const response = await axios.post(
        `${axiosDomain}/api/leads/resubmit?token=${token}`,
        submissionData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setMessage(response.data.message);
      setError('');
      // Optionally, redirect or reset form
      navigate('/thankyou');
    } catch (error) {
      console.error('Error submitting documents:', error);
      setError('Error submitting documents. Please try again.');
      setMessage('');
    } finally {
      setIsLoading(false);
    }
  };  

  // Loading State
  const [isLoading, setIsLoading] = useState(false);  

  // const handlePreview = () => {
  //   setTriggerPreview(true);
  // };

  // useEffect(() => {
  //   if (triggerPreview) {
  //     setTriggerPreview(false);
  //   }
  // }, [triggerPreview]);

  const setOtherNameChange = (e) => {
    const {value} = e.target;
    setOtherName(value);
  }
  
  return (    
    <div className="resubmission-form-container">
      <h1>Resubmit Rejected Documents</h1>

      {/* Client's Details */}
      <div className="client-details">
        <p>
          <strong>Name:</strong> {userData?.first_name} {userData?.last_name}
        </p>
        <p>
          <strong>Email:</strong> {userData?.email}
        </p>
        <p>
          <strong>Lender(s):</strong>{' '}
          {userData?.addlenders.map((lender, index) => (
            <span key={index}>
              {lender?.lender}
              {index < userData?.addlenders.length - 1 ? ', ' : ''}
            </span>
          ))}
        </p>
      </div>

      

      <form onSubmit={handleSubmit}>
        
        {rejectedDocuments.map((doc, index) => (  
          doc.doctype !== 'LOA' && doc.doctype !== 'COA' && doc.doctype !== 'Appendix' && (
          <React.Fragment key={`rejected-doc-${index}`}>
            <div className="form-group">
              <label>
                {doc.doctype}:
                <input
                  type="file"
                  name={`document_${index}`}
                  onChange={(e) =>
                    handleDocumentChange(e, index, doc.doctype)
                  }
                  required
                />
              </label>
              <span className="issue-line">Issue with previously uploaded document: <span className="issue-reason">{doc.reason}</span></span>
            </div>
          </React.Fragment>
          )
        ))}

        <div className="other-documents-section">
          <h2>Upload Additional Documents</h2>
          <span className="important-note">This is the most valuable information that can be provided to us, so we ask that you please take the time to have a thorough search of any records you may have.<br/><br/></span>
                <span className="form-text">
                For the optional additional documents that may provide evidence of account:<br/>
                Please upload here any other documentation that may be beneficial in assisting your
                lender to trace your account(s), for example a letter / communication from your 
                lender which details an account number.  
                </span>
                <br/><br/>
          <div className="form-group">
            <label>Select Document Type</label>
            <select
              name="other_document_type"
              value={otherDocumentType}
              onChange={handleOtherDocumentTypeChange}
            >
              <option value="">Select Document Type</option>
              <option value="Passport">Passport</option>
              <option value="Driving License">Driving License</option>
              <option value="Utility Bill">Utility Bill</option>
              <option value="Bank Statement">Bank Statement</option>
              <option value="Credit Card Statement">Credit Card Statement</option>
              <option value="Mortgage Statement">Mortgage Statement</option>
              <option value="Council Tax Bill">Council Tax Bill</option>      
              <option value="Other">Other</option>        
            </select>
            {otherSelected ? (
                      <input type="text" name="other_name" value={otherName} onChange={(e) => setOtherNameChange(e)} />
                    ) : ''}   

            <input
              type="file"
              name="other_documents"
              onChange={handleOtherDocumentFileChange}
            />
          </div>          
        </div>

        {/* Additional Fields for LOA */}
        {hasLOA && (
          <>
          <div className="loa-section">
            <div className="form-row">
              <div className="form-section">
                <h3>LOA Details</h3>
                <div className="form-group">
                  <label>Title:</label>
                  <input
                    type="text"
                    name="title"
                    value={loaFormData.title}
                    onChange={handleLoaChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="first_name"
                    value={loaFormData.first_name}
                    onChange={handleLoaChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="last_name"
                    value={loaFormData.last_name}
                    onChange={handleLoaChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Date of Birth:</label>
                  <input
                    type="date"
                    name="date_of_birth"
                    value={loaFormData.date_of_birth}
                    onChange={handleLoaChange}
                    required
                  />
                </div>                
              </div>

              <div className="address-details form-section">
                <h3>Address Details</h3>
                <div className="form-group">
                  <label>Current Address Line 1:</label>
                  <input
                    type="text"
                    name="current_address_line_1"
                    value={loaFormData.current_address_line_1}
                    onChange={handleLoaChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Current Address Line 2:</label>
                  <input
                    type="text"
                    name="current_address_line_2"
                    value={loaFormData.current_address_line_2}
                    onChange={handleLoaChange}
                  />
                </div>
                <div className="form-group">
                  <label>Current Address Line 3:</label>
                  <input
                    type="text"
                    name="current_address_line_3"
                    value={loaFormData.current_address_line_3}
                    onChange={handleLoaChange}
                  />
                </div>
                <div className="form-group">
                  <label>Current Address Line 4:</label>
                  <input
                    type="text"
                    name="current_address_line_4"
                    value={loaFormData.current_address_line_4}
                    onChange={handleLoaChange}
                  />
                </div>
                <div className="form-group">
                  <label>Current Post Code:</label>
                  <input
                    type="text"
                    name="current_post_code"
                    value={loaFormData.current_post_code}
                    onChange={handleLoaChange}
                    required
                  />
                </div>
              </div>            
            </div>

            <div className="form-row">
              <div className="form-section">
                <div className="form-group">                  
                  <span className="important-note">(Please note this is your lender’s agreement number, NOT our reference beginning with CA.)<br/><br/></span>
                  {loaFormData.matter_type === 'Milestone' && (
                      loaFormData.addcredits.map((credit, index) => (
                          <div className="form-group" key={index}>
                            <label>Credit Agreement Number - {loaFormData.addlenders[index].lender}: </label>
                            <input type="text" name={`credit_agreement_number${index}`} value={credit.credit_agreement_number || ''} onChange={handleLoaChange} />    
                          </div>                                                                      
                      ))
                  )}
                </div>
              </div>            
            </div>

            <div className="previous-addresses form-row">              
              <div className="form-section">
                <h3>Add Previous Addresses</h3>
                <div className="form-group">
                  <label>Previous Address Line 1:</label>
                  <input
                    type="text"
                    name="previous_address_line_1"
                    value={prevAdd.previous_address_line_1}
                    onChange={handlePrevAddChange}
                  />
                </div>
                <div className="form-group">
                  <label>Previous Address Line 2:</label>
                  <input
                    type="text"
                    name="previous_address_line_2"
                    value={prevAdd.previous_address_line_2}
                    onChange={handlePrevAddChange}
                  />
                </div>
                <div className="form-group">
                  <label>Previous Address Line 3:</label>
                  <input
                    type="text"
                    name="previous_address_line_3"
                    value={prevAdd.previous_address_line_3}
                    onChange={handlePrevAddChange}
                  />
                </div>
                <div className="form-group">
                  <label>Previous Address Line 4:</label>
                  <input
                    type="text"
                    name="previous_address_line_4"
                    value={prevAdd.previous_address_line_4}
                    onChange={handlePrevAddChange}
                  />
                </div>
                <div className="form-group">
                  <label>Previous Post Code:</label>
                  <input
                    type="text"
                    name="previous_post_code"
                    value={prevAdd.previous_post_code}
                    onChange={handlePrevAddChange}
                  />
                </div>
                <button type="button" onClick={addPreviousAddress}>
                  Add Previous Address
                </button>
              </div>
              <div className="prev-address-table form-section">
                <h3>Previous Addresses</h3>
                <table className="prev-add-table">
                  <thead>
                    <tr>
                      <th className="padd1">#</th>
                      <th className="padd2">Line 1</th>
                      <th className="padd3">Line 2</th>
                      <th className="padd4">Line 3</th>
                      <th className="padd5">Line 4</th>
                      <th className="padd6">Post Code</th>
                      <th className="padd7">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prevAddresses.map((address, index) => (
                      <tr key={index}>
                        <td className="padd1">{index + 1}</td>
                        <td className="padd2">{address.previous_address_line_1}</td>
                        <td className="padd3">{address.previous_address_line_2}</td>
                        <td className="padd4">{address.previous_address_line_3}</td>
                        <td className="padd5">{address.previous_address_line_4}</td>
                        <td className="padd6">{address.previous_post_code}</td>
                        <td className="padd7">
                          <a href="#!" onClick={() => removePreviousAddress(index)}>Delete</a>                        
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>                
              </div>
            </div>

            
            <div className="previous-names form-row">
              <div className="form-section">
                <h3>Previous Names</h3>
                <div className="form-group pnamegroup">
                  <div className="pnameinput">
                    <label>Add a name:</label>
                    <input
                      type="text"
                      value={prevName}
                      onChange={handlePrevNameChange}
                    />
                    <div className="pnamebutton">
                      <button type="button" onClick={addPreviousName}>
                        Add Previous Name
                      </button>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="prev-names-table form-section">
                <h3>Add Previous Names</h3>
                <table className="prev-name-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Previous Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prevNames.map((name, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{name}</td>
                        <td>
                          <button type="button" onClick={() => removePreviousName(index)}>
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Signature Pad */}
            {hasLOA && (
              <div className="signature-section">
                <h3>Signature</h3>
                <div className="signature-pad">
                  <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }}                                      
                    onEnd={handleSignatureEnd}
                  />
                  
                </div>
                <button type="button" onClick={clearSignature}>
                  Clear Signature
                </button>
              </div>
            )}

            {/* PdfEditor Integration */}
            {hasLOA && (
              <div className="pdf-editor-section">
                <PdfEditor
                  formData={loaFormData}
                  signatureData={signatureData}
                  onPdfGenerated={setPdfBytes}
                  triggerPreview={triggerPreview}
                  PreviousAddresses={prevAddresses}
                  PreviousNames={prevNames}
                />
              </div>
            )}
            
                                    
          </div>
          </>
        )}
        <div className="form-group checkbox-group">
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={handleCheckboxChange}
                id="confirmationCheckbox"
              />
              <label htmlFor="confirmationCheckbox" className="confirmation-label">
                I confirm that all information provided is true, complete, and correct to the best of my knowledge.
              </label>
            </div>

            
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Submitting...' : 'Submit Updated Document(s)'}
            </button>

            {message && <p className="message success">{message}</p>}
            {error && <p className="message error">{error}</p>}
      </form>
    </div>
  );
}

export default ResubmissionForm;

