import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
    const { logout, limitAccess } = useAuth();
    const navigate = useNavigate();
    
    return (        
        <nav className="navbar">
            <div className="navbar-brand">
                UTL Case Management
            </div>
            <div className="navbar-right">
                <ul>
                    {limitAccess === false && (
                        <>
                            <li onClick={() => navigate('/analytics')}>Analytics</li>
                            <li onClick={() => navigate('/pending-cases')}>Pending Cases</li>
                        </>
                    )}                  
                    <li onClick={() => navigate('/to-call')}>Clients to Call</li>
                    <li onClick={() => navigate('/push-email')}>Resend Email</li>
                    {limitAccess === false && (
                        <>
                            <li onClick={() => navigate('/reviewed-cases')}>Reviewed Cases</li>
                            <li onClick={() => navigate('/closed-cases')}>Closed Cases</li>
                            <li onClick={() => navigate('/rejection-reasons')}>Rejection Reasons</li>
                        </>
                    )}                    
                    <li onClick={logout}>Logout</li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;