// frontend/src/components/Analytics.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
} from 'recharts';
import './Analytics.css';

const Analytics = () => {
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  const [analytics, setAnalytics] = useState({
    totalClients: 0,
    totalCases: 0,
    emailsSent: 0,
    linksOpened: 0,
    infoSubmitted: 0,
    submissionRate: '0.00',
    rejectedDocuments: 0,
    batchData: [],
    batchTotals: {},
    clientStatusPercentages: {},
    caseStatusPercentages: {},
    responsesWorkedYesterday: 0,
    responsesWorkedLast7Days: 0,
    responsesWorkedOverall: 0,
    responsesAcceptedYesterday: 0,
    responsesAcceptedLast7Days: 0,
    responsesAcceptedOverall: 0,
    responsesRejectedYesterday: 0,
    responsesRejectedLast7Days: 0,
    responsesRejectedOverall: 0,
    pendingCasesYesterday: 0,
    pendingCasesLast7Days: 0,
    pendingCasesOverall: 0,
    acceptanceRateYesterday: '0.00',
    acceptanceRateLast7Days: '0.00',
    acceptanceRateOverall: '0.00',
    rejectionReasons: [],
  });

  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const response = await axios.get(`${axiosDomain}/api/analytics`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('utltoken')}`,
          },
        });
        const data = response.data;
        console.log('Analytics Data:', data);
        // Format batch data
        const formattedBatchData = data.batchData.map(item => ({
          ...item,
        }));

        setAnalytics({
          ...data,
          batchData: formattedBatchData,
        });
      } catch (error) {
        console.error('Error fetching analytics data:', error);
      }
    };

    fetchAnalytics();
  }, [axiosDomain]);

  // Pie chart data
  const linksOpenedData = [
    { name: 'Links Opened', value: analytics.linksOpened },
    { name: 'Not Opened', value: analytics.emailsSent - analytics.linksOpened },
  ];

  const infoSubmittedData = [
    { name: 'Info Submitted', value: analytics.infoSubmitted },
    {
      name: 'Not Submitted',
      value: analytics.emailsSent - analytics.infoSubmitted,
    },
  ];

  const linkVisitedVsInfoSubmittedData = [
    { name: 'Links Visited', value: analytics.linksOpened },
    { name: 'Info Submitted', value: analytics.infoSubmitted },
    {
      name: 'No Info Submitted',
      value: analytics.linksOpened - analytics.infoSubmitted,
    },
  ];

  const clientStatusData = [
    { name: 'Accepted', value: parseFloat(analytics.clientStatusPercentages.accepted) || 0 },
    { name: 'Rejected', value: parseFloat(analytics.clientStatusPercentages.rejected) || 0 },
    { name: 'Awaiting Response', value: parseFloat(analytics.clientStatusPercentages['awaiting response']) || 0 },
    { name: 'Pending', value: parseFloat(analytics.clientStatusPercentages.pending) || 0 },
  ];

  const caseStatusData = [
    { name: 'Accepted', value: parseFloat(analytics.caseStatusPercentages.accepted) || 0 },
    { name: 'Rejected', value: parseFloat(analytics.caseStatusPercentages.rejected) || 0 },
    { name: 'Awaiting Response', value: parseFloat(analytics.caseStatusPercentages['awaiting response']) || 0 },
    { name: 'Pending', value: parseFloat(analytics.caseStatusPercentages.pending) || 0 },
  ];

  const COLORS = ['#0088FE', '#FF8042', '#FFBB28', '#00C49F'];
  const CASE_STATUS_COLORS = ['#0088FE', '#FF8042', '#FFBB28', '#00C49F'];

  // Data for Follow-ups Due chart
  const totalFirstFollowUpDue = analytics.batchData.reduce(
    (acc, item) => acc + Number(item.firstFollowUpDue),
    0
  );
  const totalSecondFollowUpDue = analytics.batchData.reduce(
    (acc, item) => acc + Number(item.secondFollowUpDue),
    0
  );
  const totalThirdFollowUpDue = analytics.batchData.reduce(
    (acc, item) => acc + Number(item.thirdFollowUpDue),
    0
  );

  const followUpsDueData = [
    { name: 'First Follow-up', count: totalFirstFollowUpDue },
    { name: 'Second Follow-up', count: totalSecondFollowUpDue },
    { name: 'Third Follow-up', count: totalThirdFollowUpDue },
  ];

  // Data for Follow-ups Sent chart
  const totalFirstFollowUpsSent = analytics.batchData.reduce(
    (acc, item) => acc + Number(item.firstFollowUpsSent),
    0
  );
  const totalSecondFollowUpsSent = analytics.batchData.reduce(
    (acc, item) => acc + Number(item.secondFollowUpsSent),
    0
  );
  const totalThirdFollowUpsSent = analytics.batchData.reduce(
    (acc, item) => acc + Number(item.thirdFollowUpsSent),
    0
  );

  const followUpsSentData = [
    { name: 'First Follow-up Sent', count: totalFirstFollowUpsSent },
    { name: 'Second Follow-up Sent', count: totalSecondFollowUpsSent },
    { name: 'Third Follow-up Sent', count: totalThirdFollowUpsSent },
  ];

  // Calculate overall average response time
  const overallAvgResponseTime =
    analytics.batchData.length > 0
      ? (
          analytics.batchData.reduce((acc, item) => {
            return (
              acc +
              (item.avgResponseTime !== 'N/A'
                ? parseFloat(item.avgResponseTime)
                : 0)
            );
          }, 0) /
          analytics.batchData.filter(
            item => item.avgResponseTime !== 'N/A'
          ).length
        ).toFixed(2)
      : 'N/A';

  // Handle Download Data
  const handleDownloadData = () => {
    setIsDownloading(true);
    axios
      .get(`${axiosDomain}/api/analytics/download-analytics`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('utltoken')}`,
        },
        responseType: 'blob',
      })
      .then(response => {
        // Create a Blob from the response data
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'analytics_data.xlsx');
        // Append the link to the body
        document.body.appendChild(link);
        // Simulate click
        link.click();
        // Remove the link
        link.parentNode.removeChild(link);
        setIsDownloading(false);
      })
      .catch(error => {
        console.error('Error downloading data:', error);
        setIsDownloading(false);
      });
    
  };

  return (
    <div className="analytics-container">
      <h1>Analytics Dashboard</h1>

      <button className="download-button" onClick={handleDownloadData} disabled={isDownloading}>
        {isDownloading ? 'Downloading...' : 'Download Data'}
      </button>

      {/* Statistic Boxes */}
      <div className="stats">
        <div className="stat-item">
          <h3>Total Clients</h3>
          <p>{analytics.totalClients}</p>
        </div>
        <div className="stat-item">
          <h3>Total Cases</h3>
          <p>{analytics.totalCases}</p>
        </div>
        <div className="stat-item">
          <h3>Rejected Documents</h3>
          <p>{analytics.rejectedDocuments}</p>
        </div>
      </div>

      <div className="stats">
        <div className="stat-item">
          <h3>Emails Sent</h3>
          <p>{analytics.emailsSent}</p>
        </div>
        <div className="stat-item">
          <h3>Links Opened</h3>
          <p>{analytics.linksOpened}</p>
        </div>
        <div className="stat-item">
          <h3>Info Submitted</h3>
          <p>{analytics.infoSubmitted}</p>
        </div>
      </div>

      <div className="stats">
        <div className="stat-item">
          <h3>Submission Rate</h3>
          <p>{analytics.submissionRate}%</p>
        </div>
        <div className="stat-item">
          <h3>Avg Response Time</h3>
          <p>
            {overallAvgResponseTime !== 'NaN'
              ? `${overallAvgResponseTime} days`
              : 'N/A'}
          </p>
        </div>
        <div className="stat-item">
          <h3>Emails Not Sent</h3>
          <p>{analytics.emailsNotSent}</p>          
        </div>
      </div>

      {/* Pie Charts */}
      <div className="chart-container">
        <div className="chart-wrapper-half">
          <h2>Links Opened vs Not Opened</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={linksOpenedData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label
              >
                {linksOpenedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-wrapper-half">
          <h2>Info Submitted vs Not Submitted</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={infoSubmittedData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label
              >
                {infoSubmittedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Client and Case Status Percentages */}
      <div className="chart-container">
        <div className="chart-wrapper-half">
          <h2>Client Status Percentages</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={clientStatusData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label
              >
                {clientStatusData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={CASE_STATUS_COLORS[index % CASE_STATUS_COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-wrapper-half">
          <h2>Case Status Percentages</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={caseStatusData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label
              >
                {caseStatusData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={CASE_STATUS_COLORS[index % CASE_STATUS_COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Links Visited vs Info Submitted */}
      <div className="chart-container">
        <div className="chart-wrapper-full">
          <h2>Links Visited vs Info Submitted</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={linkVisitedVsInfoSubmittedData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label
              >
                {linkVisitedVsInfoSubmittedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Batch Analytics Chart */}
      <div className="chart-wrapper-full">
        <h2>Batch Analytics</h2>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={analytics.batchData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            barCategoryGap="20%"
            barGap="5%"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="batchNum" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Legend />
            <Bar dataKey="emailsSent" fill="#413ea0" name="Emails Sent" />
            <Bar dataKey="linksOpened" fill="#ff7300" name="Links Opened" />
            <Bar dataKey="infoSubmitted" fill="#00C49F" name="Info Submitted" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Follow-ups Due Chart */}
      <div className="chart-wrapper-full">
        <h2>Follow-ups Due</h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={followUpsDueData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#8884d8" name="Follow-ups Due" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Follow-ups Sent Chart */}
      <div className="chart-wrapper-full">
        <h2>Follow-ups Sent</h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={followUpsSentData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#82ca9d" name="Follow-ups Sent" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Batch Data Table */}
      <div className="table-wrapper-full">
        <h2>Batch Data</h2>
        <div className="table-container">
          <table className="batch-data-table">
            <thead>
              <tr>
                <th>Batch Number</th>
                <th>Number of Clients</th>
                <th>Number of Cases</th>
                <th>Emails Sent</th>
                <th>Links Opened</th>
                <th>Info Submitted</th>
                <th>Conversion (%)</th>
                <th>Avg Response Time (days)</th>
                <th>First Follow-up Due</th>
                <th>Second Follow-up Due</th>
                <th>Third Follow-up Due</th>
                <th>Pending Cases</th>
              </tr>
            </thead>
            <tbody>
              {analytics.batchData.map((item, index) => (
                <tr key={index}>
                  <td>{item.batchNum}</td>
                  <td>{item.numberOfClients}</td>
                  <td>{item.numberOfCases}</td>
                  <td>{item.emailsSent}</td>
                  <td>{item.linksOpened}</td>
                  <td>{item.infoSubmitted}</td>
                  <td>{item.conversion}%</td>
                  <td>{item.avgResponseTime}</td>
                  <td>{item.firstFollowUpDue}</td>
                  <td>{item.secondFollowUpDue}</td>
                  <td>{item.thirdFollowUpDue}</td>
                  <td>{item.pendingCases}</td>
                </tr>
              ))}
              {/* Totals Row */}
              <tr>
                <td><strong>Totals</strong></td>
                <td><strong>{analytics.batchTotals.numberOfClients}</strong></td>
                <td><strong>{analytics.batchTotals.numberOfCases}</strong></td>
                <td><strong>{analytics.batchTotals.emailsSent}</strong></td>
                <td><strong>{analytics.batchTotals.linksOpened}</strong></td>
                <td><strong>{analytics.batchTotals.infoSubmitted}</strong></td>
                <td>{(Number(analytics.batchTotals.infoSubmitted) / Number(analytics.batchTotals.emailsSent)*100).toFixed(2)}%</td>
                <td>-</td>
                <td><strong>{analytics.batchTotals.firstFollowUpDue}</strong></td>
                <td><strong>{analytics.batchTotals.secondFollowUpDue}</strong></td>
                <td><strong>{analytics.batchTotals.thirdFollowUpDue}</strong></td>
                <td><strong>{analytics.batchTotals.pendingCases}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Response Processing Section */}
      <div className="response-processing">
        <h2>Response Processing (Case Level)</h2>
        <div className="table-container">
          <table className="response-processing-table">
            <thead>
              <tr>
                <th>Metric</th>
                <th>Yesterday</th>
                <th>Last 7 Days</th>
                <th>Overall</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tablecol1">Responses Worked</td>
                <td>{analytics.responsesWorkedYesterday}</td>
                <td>{analytics.responsesWorkedLast7Days}</td>
                <td>{analytics.responsesWorkedOverall}</td>
              </tr>
              <tr>
                <td className="tablecol1">Responses Accepted</td>
                <td>{analytics.responsesAcceptedYesterday}</td>
                <td>{analytics.responsesAcceptedLast7Days}</td>
                <td>{analytics.responsesAcceptedOverall}</td>
              </tr>
              <tr>
                <td className="tablecol1">Responses Rejected</td>
                <td>{analytics.responsesRejectedYesterday}</td>
                <td>{analytics.responsesRejectedLast7Days}</td>
                <td>{analytics.responsesRejectedOverall}</td>
              </tr>
              <tr>
                <td className="tablecol1">Pending Cases</td>
                <td>{analytics.pendingCasesYesterday}</td>
                <td>{analytics.pendingCasesLast7Days}</td>
                <td>{analytics.pendingCasesOverall}</td>
              </tr>
              <tr>
                <td className="tablecol1">Conversion (Accepted vs Worked)</td>
                <td>{analytics.acceptanceRateYesterday}%</td>
                <td>{analytics.acceptanceRateLast7Days}%</td>
                <td>{analytics.acceptanceRateOverall}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Rejection Reasons */}
      <div className="rejection-reasons">
        <h2>Rejection Reasons (Document Level)</h2>
        <div className="table-container">
          <table className="rejection-reasons-table">
            <thead>
              <tr>
                <th className="tablecol1">Rejection Reason</th>
                <th>Yesterday</th>
                <th>Last 7 Days</th>
                <th>Overall</th>
              </tr>
            </thead>
            <tbody>
              {analytics.rejectionReasons.map((item, index) => (
                <tr key={index}>
                  <td className="tablecol1">{item.rejection_reason}</td>
                  <td>{item.yesterday || 0}</td>
                  <td>{item.last7days || 0}</td>
                  <td>{item.overall || 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
