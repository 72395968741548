import React from 'react';

const ThankYou = () => {

    return (
        <>
        {/* <header className="form-header">
            <img src="/clllogo.png" alt="Logo" className="logo" /> 
          </header> */}
        <div>                          
          <center>
            <h3>Thank you for submitting the information</h3>                                                  
          </center>
        </div>
        </>
      );

}

export default ThankYou;