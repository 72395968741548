import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './dashboard.css';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {    
    const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
    const [counts, setCounts] = useState({
        pending: 0,
        reviewed: 0,
        closed: 0,
    });
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCounts = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${axiosDomain}/api/case-counts`, {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
                });                
                setCounts(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching case counts:', error);
                setIsLoading(false);
            }
        };
        fetchCounts();
    }, []);

    return (
        <div className="dashboard-container">
            <h2>Dashboard</h2>
            <div className="dashboard-grid">
                <a href="/pending-cases">
                    <div className="dashboard-box">                    
                        <h3>Pending Cases</h3>
                        <p>{isLoading ? 'Loading...' : counts.pending}</p>
                    </div>
                </a>
                <a href="/reviewed-cases">                    
                    <div className="dashboard-box">
                        <h3>Reviewed Cases</h3>
                        <p>{isLoading ? 'Loading...' : counts.reviewed}</p>
                    </div>
                </a>
                <a href="/closed-cases">                                        
                    <div className="dashboard-box">
                        <h3>Closed Cases</h3>
                        <p>{isLoading ? 'Loading...' : counts.closed}</p>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default Dashboard;
