import React, { useState } from 'react';
import axios from 'axios';
import './login.css';
import { useAuth } from '../../context/AuthContext';

const TeamLogin = () => {
    const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login, setLimitAccess } = useAuth();

    const [error, setError] = useState('');
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${axiosDomain}/api/login`, { username, password });    
            if (response.data.role === 'restricted') {
                setLimitAccess(true);
            } else {
                setLimitAccess(false);
            }                           
            login(response.data.token);                                                
        } catch (error) {
            console.log('error:', error);
            setError('Invalid login credentials');
        }
    };

    return (                          
        <div className="login-container">
            <div className="login-form-team">
                <div className="login-header">                
                <h2>ClaimLion Law Users Login</h2>
                </div>
                <form onSubmit={handleSubmit}>
                <label htmlFor="username">Username</label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />

                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />

                {error && <p className="error-message">{error}</p>}

                <button className="team-login-button" type="submit">Login</button>
                </form>
            </div>
        </div>        
    );
};

export default TeamLogin;
