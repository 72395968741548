import React, { useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './LeadForm.css'; 
import PdfEditor from './PdfEditor';
import Loader from './Loader';

const LeadForm = (userData) => {
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  const navigate = useNavigate();
  const sigCanvas = useRef(null);    
  const [triggerPreview, setTriggerPreview] = useState(false);  
  const [formData, setFormData] = useState({
    title: '',
    first_name: '',
    last_name: '',    
    date_of_birth: '',    
    current_address_line_1: '',
    current_address_line_2: '',
    current_address_line_3: '',
    current_address_line_4: '',
    current_post_code: '',
    previous_names: '',    
    credit_agreement_number: '',
    car_dealership_name: '',
    vehicle_registration_number: '',
    lender: '',
    matter_type: '',
    email: '',
    telephone: '',
    mobile: '',
    document_required_1: '',
    document_required_2: '',
    document_required_3: '',
    document_required_4: '',
    document_required_5: '',
    documents: [null],
    documents_uploaded: '',
    updated_info: '',
    cancel_request: '',
    addlenders: [],
    addcredits: [],
    adddealerships: [],
    addvehicles: [],
    addcases: [],
    ip: '',
    previousadds: [],
    LOA_req: '',
    POI_req: '',
    POA_req: '',
    document_types: []
  });
  
  // New States for Confirmation and Expiry Dates
  const [confirmedFields, setConfirmedFields] = useState({});
  const [expiryDates, setExpiryDates] = useState({});
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [caseId, setCaseId] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('tk');
  const [pdfBytes, setPdfBytes] = useState(null);
  const [prevAddresses, setPrevAddresses] = useState([]);  
  const [prevAdd, setPrevAdd] = useState({
    previous_address_line_1: '',
    previous_address_line_2: '',
    previous_address_line_3: '',
    previous_address_line_4: '',
    previous_post_code: ''
  });
  const [prevNames, setPrevNames] = useState([]);
  const [prevName, setPrevName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [otherSelected, setOtherSelected] = useState(false);
  const [otherName, setOtherName] = useState(null);
  const [poiSelected, setPOISelected] = useState(null);
  const [poaSelected, setPOASelected] = useState(null);    
  const [checkFieldErrors, setCheckFieldErrors] = useState([]);
  const [nameChanged, setNameChanged] = useState(false);
  const [originalFirstName, setOriginalFirstName] = useState('');
  const [originalLastName, setOriginalLastName] = useState('');

  
  useEffect(() => {
    if (userData && userData.userData) {
      setFormData({
        title: userData.userData.title || '',
        first_name: userData.userData.first_name || '',
        last_name: userData.userData.last_name || '',        
        date_of_birth: userData.userData.date_of_birth || '',        
        current_address_line_1: userData.userData.current_address_line_1 || '',
        current_address_line_2: userData.userData.current_address_line_2 || '',
        current_address_line_3: userData.userData.current_address_line_3 || '',
        current_address_line_4: userData.userData.current_address_line_4 || '',
        current_post_code: userData.userData.current_post_code || '',
        previous_names: userData.userData.previous_names || '',        
        credit_agreement_number: userData.userData.credit_agreement_number || '',
        car_dealership_name: userData.userData.car_dealership_name || '',
        vehicle_registration_number: userData.userData.vehicle_registration_number || '',
        lender: userData.userData.lender || '',
        matter_type: userData.userData.matter_type || '',
        email: userData.userData.email || '',
        telephone: userData.userData.telephone || '',
        mobile: userData.userData.mobile || '',
        document_required_1: userData.userData.document_required_1 || '',
        document_required_2: userData.userData.document_required_2 || '',
        document_required_3: userData.userData.document_required_3 || '',
        document_required_4: userData.userData.document_required_4 || '',
        document_required_5: userData.userData.document_required_5 || '',
        documents: [null],
        cancel_request: '0',
        updated_info: userData.userData.updated_info || '0',
        documents_uploaded: userData.userData.documents_uploaded || '0',
        addlenders: userData.userData.addlenders || [],
        addcredits: userData.userData.addcredits || [],
        adddealerships: userData.userData.adddealerships || [],
        addvehicles: userData.userData.addvehicles || [],
        addcases: userData.userData.addcases || [],
        ip: userData.userData.ip || '',
        previousadds: userData.userData.previousadds || [],
        LOA_req: userData.userData.LOA_req || '',
        POI_req: userData.userData.POI_req || '',
        POA_req: userData.userData.POA_req || '',
        document_types: [null]
      });
      setOriginalFirstName(userData.userData.first_name || '');
      setOriginalLastName(userData.userData.last_name || '');      

      setCaseId(userData.userData.case_id || '');            
      if (userData.userData.previousadds && userData.userData.previousadds.length > 0) {
        setPrevAddresses(userData.userData.previousadds);
      }             
      if (userData.userData.previous_names && userData.userData.previous_names !== '') {
        setPrevNames(userData.userData.previous_names.split(','));
      }      
    }                    
  }, [userData]);

  const handleAddDocument = () => {
    setFormData({
      ...formData,
      documents: [...formData.documents, null]
    });
  };

  const handleCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handlePrevAddChange = (e) => {
    const { name, value } = e.target;
    setPrevAdd({
      ...prevAdd,
      [name]: value
    });
  };  

  const addPreviousAddress = () => {    
    const existingAdds = prevAddresses;
    const newAdd = {
      previous_address_line_1: prevAdd.previous_address_line_1,
      previous_address_line_2: prevAdd.previous_address_line_2,
      previous_address_line_3: prevAdd.previous_address_line_3,
      previous_address_line_4: prevAdd.previous_address_line_4,
      previous_post_code: prevAdd.previous_post_code
    };
    const newAdds = [...existingAdds, newAdd];
    setPrevAddresses([...prevAddresses, prevAdd]);
    setPrevAdd({
      previous_address_line_1: '',
      previous_address_line_2: '',
      previous_address_line_3: '',
      previous_address_line_4: '',
      previous_post_code: ''
    });
    setFormData({
      ...formData,
      previousadds: newAdds      
    })
  };

  const removePreviousAddress = (index) => {    
    const newAddresses = [...prevAddresses];
    newAddresses.splice(index, 1);
    setPrevAddresses(newAddresses);
    setFormData({
      ...formData,
      previousadds: newAddresses
    });
  };

  const handlePrevNameChange = (e) => {
    setPrevName(e.target.value);
  };

  const addPreviousName = () => {
    const existingNames = prevNames;
    const NewNames = existingNames + ',' + prevName;
    
    setPrevNames([...prevNames, prevName]);
    setFormData({
      ...formData,
      previous_names: NewNames
    });    
    setPrevName('');
  };

  const removePreviousName = (index) => {
    const newNames = [...prevNames];
    newNames.splice(index, 1);
    setPrevNames(newNames);
    setFormData({
      ...formData,
      previous_names: newNames.join(',')
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const checkFields = ['title', 'first_name', 'last_name', 'date_of_birth', 'telephone', 'mobile', 'current_address_line_1',
                          'current_address_line_2', 'current_address_line_3', 'current_address_line_4', 'current_post_code'];
    //Checking if the non-blank fields have been confirmed
    let checkErrors = [];
    checkFields.forEach(field => {            
      if (formData[field] && formData[field] !== '') {        
        if (!confirmedFields[field]) {          
          setError('Please confirm all fields with values.');
          checkErrors.push(field);
          return;
        }
      }
    });

    setCheckFieldErrors(checkErrors);

    if (checkErrors.length > 0) {
      return;
    }        

    // Validate POI and POA selections
    if (formData.POI_req === 1 && !poiSelected) {
      setError('Please select a document type for the Proof of Identity document.');
      return;
    }
    if (formData.POA_req === 1 && !poaSelected) {
      setError('Please select a document type for the Proof of Address document.');
      return;
    }

    // Validate expiry dates for Passport and Driving Licence
    for (const [index, docType] of formData.document_types.entries()) {
      if (docType === 'Passport' || docType === 'Driving Licence') {
        const expiryDate = expiryDates[index];
        if (!expiryDate) {
          setError(`Please provide an expiry date for your ${docType}.`);
          return;
        }
        const today = new Date();
        const minExpiryDate = new Date(today.setMonth(today.getMonth() + 1));
        const selectedDate = new Date(expiryDate);
        if (selectedDate < minExpiryDate) {
          setError(`The expiry date for your ${docType} must be at least one month from today.`);
          return;
        }
      }
    }

    if (!termsAccepted) {
      setError('Declaration box not checked.');
      return;
    }

    if (sigCanvas.current.isEmpty()) {
      setError('Please provide a signature.');
      return;
    }

    if (!formData.first_name || !formData.last_name || !caseId) {
      setError('Please fill out all required fields.');
      return;
    }

    if (nameChanged && !formData.documents[3]) {
      setError('Please upload proof of name change.');
      return;
    }    
            
    if (!otherName && formData.documents[2]) {                  
      setError('Please select a document type for the "Other" document.');
      return;
    }

    if (otherSelected && (otherName  === '' || !otherName) && formData.documents[2]) {
      setError('Please select a document type for the "Other" document.');
      return;
    }

    if (otherName && otherName !== '' && !formData.documents[2]) {      
      setError('Please upload the "Other" document.');
      return;
    }

    setIsLoading(true);
    const data = new FormData();    
    data.append('caseId', caseId);
    for (const key in formData) {
      if (formData.hasOwnProperty(key) && key !== 'documents') {
        if (typeof formData[key] === 'object') {
          data.append(key, JSON.stringify(formData[key]));
        } else {
          data.append(key, formData[key]);     
        }        
      }
    }          
    
    let totalSize = 0;
    formData.documents.forEach((document) => {
        if (document) {
            totalSize += document.size;
        }
    });
    if (pdfBytes) {
      pdfBytes.loaPDFs.forEach((doc) => {
          totalSize += doc.data.length;
      });
      pdfBytes.coaPDFs.forEach((doc) => {
          totalSize += doc.data.length;
      });
      if (pdfBytes.appendixPDFs) {
          pdfBytes.appendixPDFs.forEach((doc) => {
              totalSize += doc.data.length;
          });
      }
    }

    if (totalSize > 15 * 1024 * 1024) { // 15MB in bytes
      setError('The attachments exceed 15MB. Please ensure you upload smaller files / images.');
      setIsLoading(false);
      return;
    }

    sigCanvas.current.getTrimmedCanvas().toBlob(async (blob) => {
      data.append('signature', blob, 'signature.png');
      
      formData.documents.forEach((document, index) => {                
        const documentType = formData.document_types[index];
        if (document) {                              
          const originalName = document.name;
          const extension = originalName.slice(originalName.lastIndexOf('.'));
          const newFilename = `${documentType}_${index+1}${extension}`;
          data.append('documents', document, newFilename);                    
        }
      });

      if (pdfBytes) {
        pdfBytes.loaPDFs.forEach((doc) => {
          const pdfBlob = new Blob([doc.data], { type: 'application/pdf' });
          data.append(`LOA`, pdfBlob, `${doc.caseRef}_${formData.last_name}_${doc.lender}_LOA.pdf`);
        });
        pdfBytes.coaPDFs.forEach((doc) => {
          const pdfBlob = new Blob([doc.data], { type: 'application/pdf' });
          data.append(`COA`, pdfBlob, `${doc.caseRef}_${formData.last_name}_${doc.lender}_COA.pdf`);
        });
        if (pdfBytes.appendixPDFs) {
          pdfBytes.appendixPDFs.forEach((doc) => {
            const pdfBlob = new Blob([doc.data], { type: 'application/pdf' });
            data.append(`Appendix`, pdfBlob, `Appendix.pdf`);
          });
        }                
      }
      
      try {                        
        const response = await axios.post(`${axiosDomain}/api/leads/submit?token=${token}`, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setMessage(response.data.message);
        setIsLoading(false);
        setError('');
        resetForm();
        navigate('/thankyou');        
      } catch (error) {
        if (error.response && error.response.data.includes('Duplicate entry')) {
          setError('Submission failed: Duplicate entry.');
          setIsLoading(false);
        } else {
          setError('Submission failed: ' + error.message);
          setIsLoading(false);
        }
        setMessage('');
        
      }
    }, 'image/png');
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const resetForm = () => {
    setFormData({
      title: '',
      first_name: '',
      last_name: '',      
      date_of_birth: '',      
      current_address_line_1: '',
      current_address_line_2: '',
      current_address_line_3: '',
      current_address_line_4: '',
      current_post_code: '',
      previous_names: '',      
      credit_agreement_number: '',
      car_dealership_name: '',
      vehicle_registration_number: '',
      lender: '',
      matter_type: '',
      email: '',
      telephone: '',
      mobile: '',
      document_required_1: '',
      document_required_2: '',
      document_required_3: '',
      document_required_4: '',
      document_required_5: '',
      documents: [null],
      documents_uploaded: '',
      updated_info: '',
      cancel_request: '',
      addlenders: [],
      addcredits: [],
      adddealerships: [],
      addvehicles: [],
      addcases: [],
      previousadds: [],
      ip: '',
      LOA_req: '',
      POI_req: '',
      POA_req: '',
      document_types: []
    });
    clearSignature();
    document.querySelectorAll('input[name="documents"]').forEach(input => input.value = null);
    setTermsAccepted(false);
    setConfirmedFields({});
    setExpiryDates({});
  };

  const handlePreview = () => {
    setTriggerPreview(true);
  };

  useEffect(() => {
    if (triggerPreview) {
      setTriggerPreview(false);
    }
  }, [triggerPreview]);

  const handleSelectionChange = (e) => {
    const { name, value } = e.target;
    // Existing logic (if any)
  }

  const setOtherDocumentChange = (e) => {
    const { value } = e.target;    
    if (value === 'Other') {   
      setOtherSelected(true);   
      setOtherName(value);
    } else {      
      setOtherSelected(false);
      setOtherName(value);
    }
  };

  const setOtherNameChange = (e) => {
    const {value} = e.target;
    setOtherName(value);
  }

  const handleChange = (e, index, dType) => {
    const { name, value, files } = e.target;
    if (name === 'documents') {
      const newDocuments = [...formData.documents];
      const docTypes = [...formData.document_types];
      newDocuments[index] = files[0];
      docTypes[index] = dType;

      setFormData({
        ...formData,
        documents: newDocuments,
        documents_uploaded: '1',
        document_types: docTypes
      });

      // Reset expiry date if not Passport or Driving Licence
      if (dType !== 'Passport' && dType !== 'Driving Licence') {
        setExpiryDates(prev => {
          const updated = { ...prev };
          delete updated[index];
          return updated;
        });
      }
    } else {
      if (name === 'first_name' || name === 'last_name') {
        if (name === 'first_name') {
          if (value !== originalFirstName) {
            setNameChanged(true);
          } else {
            setNameChanged(false);
          }
        } else if (name === 'last_name') {
          if (value !== originalLastName) {
            setNameChanged(true);
          } else {
            setNameChanged(false);
          }
        }
      }      

      if (name.includes('credit_agreement_number')) {        
        const nameIndex = name.replace('credit_agreement_number', '')                
        const creditIndex = Number(nameIndex);
        const newCredits = [...formData.addcredits];
        newCredits[creditIndex].credit_agreement_number = value;
        setFormData({
          ...formData,
          addcredits: newCredits
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
          updated_info: '1'
        });
      }
    }
  };

  const RenderUploadText = () => {
    if (formData['POI_req'] === 1 || formData['POA_req'] === 1) {              
          return (
            <>
            <div className='upload-div'>
            Further documentation has either been directly requested by your lender or 
              is required to give the lender the best possible chance of tracing your account. 
              <br/><br/>
              Please upload a copy of the documentation specified.
              <br/><br/>
              {formData['POI_req'] === 1 ? (
                <>
                  <div className="upload-section">
                  <h3>Upload POI Document<span className="docstar">*</span></h3>
                  <strong>Proof of Identity</strong><br/>
                  (Ideal forms of Proof of Iidentity are: a copy of your current signed passport or current UK photo card driving licence.)                                  
                  <div key='0' className="form-group form-upload">                  
                    <label>Select Document Type</label>
                    <select name="doc_selection" defaultValue="Select Document Type" onChange={(e) => setPOISelected(e.target.value)}>
                      <option value="Select Document Type" disabled>
                              Select Document Type
                      </option>                                            
                      <option value="Passport">Passport</option>
                      <option value="Driving Licence">Driving Licence</option>
                    </select>                  
                    <input
                        type="file"
                        name="documents"
                        onChange={(e) => handleChange(e, 0, poiSelected)}     
                        required             
                        disabled={poiSelected === 'Passport' || poiSelected === 'Driving Licence' ? false : true}    
                    /> 
                    {/* Expiry Date Field */}
                    {(poiSelected === 'Passport' || poiSelected === 'Driving Licence') && (
                      <div className="form-group">
                        <label>Expiry Date<span className="docstar">*</span>:</label>
                        <input
                          type="date"
                          name={`expiry_date_${0}`}
                          value={expiryDates[0] || ''}
                          onChange={(e) => setExpiryDates({ ...expiryDates, [0]: e.target.value })}
                          required
                        />
                      </div>
                    )}                 
                  </div>   
                  <br/><span className="important-note-poi">*Please ensure that your name on the uploaded document matches the one in our system (on your LOA).</span>
                  <br/><span className="important-note-poi">*Please ensure that the uploaded document has not expired.</span>
                </div>                                 
                </>
              ) : ''}        
              <div className="border-div"></div>
              {formData['POA_req'] === 1 ? (
                <>
                <div className="upload-section">
                  <h3>Upload POA Document<span className="docstar">*</span></h3>
                  <strong>Proof of Address</strong><br/>
                  Examples of acceptable forms of proof of address are:                  
                  <br/><br/>
                  <ul>
                    <li>A Utility Bill  (dated within the last three months)</li>
                    <li>A Bank Statement  (dated within the last three months)</li>                    
                    <li>A Mortgage Statement (dated within the last 12 months)</li>
                    <li>A Council Tax Bill or Payment Book (dated within the last 12 months)</li>
                </ul>
                  <div key='1' className="form-group">
                    <label>Select Document Type</label>
                      <select name="doc_selection1" defaultValue="Select Document Type" onChange={(e) => setPOASelected(e.target.value)}>
                      <option value="Select Document Type" disabled>
                        Select Document Type
                      </option>                                            
                        <option value="Utility Bill">Utility Bill</option>
                        <option value="Bank Statement">Bank Statement</option>                        
                        <option value="Mortgage Statement">Mortgage Statement</option>
                        <option value="Council Tax Bill">Council Tax Bill</option>                    
                      </select>
                    <label>Upload POA Document</label>
                    <input
                        type="file"
                        name="documents"
                        onChange={(e) => handleChange(e, 1, poaSelected)} 
                        required                 
                        disabled={poaSelected === 'Utility Bill' || poaSelected === 'Bank Statement' || poaSelected === 'Mortgage Statement' || poaSelected === 'Council Tax Bill' ? false : true}                      
                    />                  
                  </div>
                  <br/><span className="important-note-poi">*Please ensure that your name on the uploaded document matches the one in our system (on your LOA).</span>
                  <br/><span className="important-note-poi">*Please ensure that the uploaded document has not expired.</span>
                  <br/><span className="important-note-poi">*The address on the document should be the same as the one in our system (on your LOA).</span>
                </div>     
                </>
              ) : ''}           
              <div className="border-div"></div>
              {/* Section to show if name is changed */}
              {nameChanged && (
                <div className="upload-section name-change">                
                  <h3>Upload Proof of Name Change</h3>
                  <span className="important-note">You have updated your name. The updated one doesn't match our records. Please provide a proof of name change<br/><br/></span>                                    
                  Please upload proof of name change documentation that may be beneficial in assisting your lender to trace your account(s), for example a marriage certificate.                                    
                  <div key='3' className="form-group">                                        
                    <input
                        type="file"
                        name="documents"
                        onChange={(e) => handleChange(e, 3, 'Proof of Name Change')}                                                         
                    />                                                                       
                  </div>      
                  <br/><br/>                  
                </div>
              )}
              <div className="border-div"></div>
              <div className="upload-section">                
                <h3>Upload Other Document</h3>
                <span className="important-note">This is the most valuable information that can be provided to us, so we ask that you please take the time to have a thorough search of any records you may have.<br/><br/></span>
                For the optional additional documents that may provide evidence of account:
                <br/><br/>
                Please upload here any other documentation that may be beneficial in assisting your
                lender to trace your account(s), for example a letter / communication from your 
                lender which details an account number.  
                <div key='2' className="form-group">
                  <label>Select Other Document Type</label>
                  <select name="doc_selection1" defaultValue="Select Document Type" onChange={(e) => setOtherDocumentChange(e)}>
                    <option value="Select Document Type" disabled>
                      Select Document Type
                    </option>                                            
                    <option value="Passport">Passport</option>
                    <option value="Driving License">Driving License</option>
                    <option value="Utility Bill">Utility Bill</option>
                    <option value="Bank Statement">Bank Statement</option>
                    <option value="Credit Card Statement">Credit Card Statement</option>
                    <option value="Mortgage Statement">Mortgage Statement</option>
                    <option value="Council Tax Bill">Council Tax Bill</option>                    
                    <option value="Other">Other</option>                    
                  </select>
                  {otherSelected ? (
                    <input type="text" name="other_name" value={otherName} onChange={(e) => setOtherNameChange(e)} />
                  ) : ''}    

                  <input
                      type="file"
                      name="documents"
                      onChange={(e) => handleChange(e, 2, otherName)}                                                         
                  />                                                                       
                </div>      
                <br/><br/>
                If you are unsure of what documentation you should upload please contact our Customer
                Service Team by email on assistance@claimlionlaw.com or by calling (0)800 599 9993.
              </div>
              



          </div>
        </>
      );
    } else {
      return (<></>);    
    }
  }

  const handleFieldConfirmation = (fieldName) => {
    setConfirmedFields({
      ...confirmedFields,
      [fieldName]: true
    });
    if (checkFieldErrors.includes(fieldName)) {
      setCheckFieldErrors(checkFieldErrors.filter(field => field !== fieldName));
    }
  };

  const RenderFieldWithConfirmation = (label, name, type = "text") => {
    const hasValue = formData[name] && formData[name] !== '';
    const isConfirmed = confirmedFields[name];
    return (
      <div className="form-group">
        <label>{label}:</label>
        <input
          type={type}
          name={name}
          value={formData[name]}
          onChange={(e) => {
            handleChange(e);
            // Reset confirmation if value changes
            setConfirmedFields({
              ...confirmedFields,
              [name]: false
            });
          }}
          className={`${hasValue && !isConfirmed ? 'highlight' : ''} ${checkFieldErrors.includes(name) ? 'checkerror' : ''}`}
        />
        {hasValue && (
          <button
            type="button"
            className={`confirm-button ${isConfirmed ? 'confirmed' : ''}`}
            onClick={() => handleFieldConfirmation(name)}
          >
            {'✓'}
          </button>
        )}
      </div>
    );
  };
  
  return (    
    <>
    {isLoading ? (
      <>        
        <Loader />          
      </>
      ) : (
      <>      
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
              <div className="form-row">
                <div className="form-section row1col1">
                  <label>Email:</label>
                  <p>{formData.email}</p>              
                  <br/>
                  <label>Lender(s):</label>                  
                  {formData.addlenders.map((lender, index) => (
                    <p key={index}>{lender.lender}</p>
                  ))}
                </div>
                <div className="form-section row1col2">              
                  <p className="header-note">
                    Please provide as much detail as possible in the fields on this screen.<br/><br/>
                    If any detail is incorrect please type over the current information and add any detail that is missing. <br/><br/>
                    The detail you enter is used by lenders to trace your accounts/records so they can release to us the detail about your PCP agreement(s) we need to progress your claim. <br/><br/>
                    Lenders use previous addresses and previous names to trace records so please add all the detail you can. <br/><br/>
                    An account number (for each lender if applicable)  would be extremely beneficial.  
                  </p>                  
                </div>
              </div>
          </div>
          <div className="form-row">                
              
            <div className="user-details form-section">                    
                <h3>User Details <span className="text-with-heading">(Please click all the checkboxes to confirm the information)</span></h3>   
                                                     
                {RenderFieldWithConfirmation('Title', 'title')}
                {RenderFieldWithConfirmation('First Name', 'first_name')}
                {RenderFieldWithConfirmation('Last Name', 'last_name')}
                {RenderFieldWithConfirmation('Date of Birth', 'date_of_birth', 'date')}
                {RenderFieldWithConfirmation('Telephone', 'telephone')}
                {RenderFieldWithConfirmation('Mobile', 'mobile')}
            </div>

            <div className="address-details form-section">
                <h3>Address Details</h3>
                {RenderFieldWithConfirmation('Current Address Line 1', 'current_address_line_1')}
                {RenderFieldWithConfirmation('Current Address Line 2', 'current_address_line_2')}
                {RenderFieldWithConfirmation('Current Address Line 3', 'current_address_line_3')}
                {RenderFieldWithConfirmation('Current Address Line 4', 'current_address_line_4')}
                {RenderFieldWithConfirmation('Current Post Code', 'current_post_code')}
                
            </div>
                                                                 
          </div>
          <div className="form-row">                
            <div className="prev-address-details form-section">
                <h3>Previous Address Details</h3>
                <span className="title-text">If you have more than one previous address, please add them to the list.</span>
                <br/><br/>

                <div className="form-group">
                    <label>Previous Address Line 1:</label>
                    <input type="text" name="previous_address_line_1" value={prevAdd.previous_address_line_1} onChange={handlePrevAddChange} />
                </div>
                <div className="form-group">
                    <label>Previous Address Line 2:</label>
                    <input type="text" name="previous_address_line_2" value={prevAdd.previous_address_line_2} onChange={handlePrevAddChange} />
                </div>
                <div className="form-group">
                    <label>Previous Address Line 3:</label>
                    <input type="text" name="previous_address_line_3" value={prevAdd.previous_address_line_3} onChange={handlePrevAddChange} />
                </div>
                <div className="form-group">
                    <label>Previous Address Line 4:</label>
                    <input type="text" name="previous_address_line_4" value={prevAdd.previous_address_line_4} onChange={handlePrevAddChange} />
                </div>
                <div className="form-group">
                    <label>Previous Post Code:</label>
                    <input type="text" name="previous_post_code" value={prevAdd.previous_post_code} onChange={handlePrevAddChange} />
                </div>                    
                <button type="button" className="paddbutton" onClick={() => addPreviousAddress()}>Add Previous Address</button>                
            </div>                                            
            <div className="prev-address-table form-section">
              <h3>Previous Addresses</h3>
              <table className="prev-add-table">
                <thead>
                  <tr>
                    <th className="padd1">#</th>
                    <th className="padd2">Line 1</th>
                    <th className="padd3">Line 2</th>
                    <th className="padd4">Line 3</th>
                    <th className="padd5">Line 4</th>
                    <th className="padd6">Post Code</th>
                    <th className="padd7">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {prevAddresses.map((address, index) => (
                    <tr key={index}>
                      <td className="padd1">{index + 1}</td>
                      <td className="padd2">{address.previous_address_line_1}</td>
                      <td className="padd3">{address.previous_address_line_2}</td>
                      <td className="padd4">{address.previous_address_line_3}</td>
                      <td className="padd5">{address.previous_address_line_4}</td>
                      <td className="padd6">{address.previous_post_code}</td>
                      <td className="padd7">
                        <a href="#!" onClick={() => removePreviousAddress(index)}>Delete</a>                        
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>                
            </div>
          </div>

          <div className="form-row">            
            <div className="matter-details form-section">
                <h3>Case Related Details</h3>
                <span className="important-note title-text">Please note this is your lender’s agreement number, NOT our reference beginning with CA.<br/><br/></span>
                {formData.matter_type === 'Milestone' && (
                    formData.addcredits.map((credit, index) => (
                        <div className="form-group" key={index}>
                          <label>Credit Agreement Number - {formData.addlenders[index].lender}: </label>
                          <input type="text" name={`credit_agreement_number${index}`} value={credit.credit_agreement_number || ''} onChange={handleChange} />
                        </div>                                                                      
                    ))
                )}
                                                                                            
                {formData.matter_type === 'PCP' && (
                    formData.addvehicles.map((vehicle, index) => (                      
                      <React.Fragment key={index}>
                      <div className="form-group">
                          <label>Car Dealership Name:</label>
                          <input type="text" name="car_dealership_name" value={formData.adddealerships[index].car_dealership_name} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                          <label>Vehicle Registration Number:</label>
                          <input type="text" name="vehicle_registration_number" value={vehicle.vehicle_registration_number} onChange={handleChange} />
                      </div>
                      </React.Fragment>
                    ))
                )}

                
            </div>    

            <div className="previous-names form-section">
                <h3>Previous Names</h3>
                <div className="form-group pnamegroup">
                  <div className="pnameinput">
                    <label>Add a name:</label>
                    <input type="text" value={prevName} onChange={handlePrevNameChange} />                    
                    <div className="pnamebutton">
                      <button type="button" onClick={addPreviousName}>Add Previous Name</button>
                    </div>
                  </div>
                  
                </div>
                <div className="prev-names-table">
                  <table className="prev-name-table">
                    <thead>
                      <tr>
                        <th className="padd1">#</th>
                        <th className="padd2">Previous Name</th>
                        <th className="padd3">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prevNames.map((name, index) => (
                        <tr key={index}>
                          <td className="padd1">{index + 1}</td>
                          <td className="padd2">{name}</td>
                          <td className="padd3">
                            <a href="#!" onClick={() => removePreviousName(index)}>Delete</a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>                
            </div>
          </div>    

        <div className="form-row signaturerow"> 
            <div className="signature-details form-section">
            <div className="form-group">
                <label>Signature:</label>
                <span className="important-note">*If you are required to upload a Proof of Identity, please make sure your signature matches the signature on your uploaded document. </span>
                <div className="signature-pad">
                  <SignatureCanvas ref={sigCanvas} canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }} />
                </div>
                <span className="important-note">*Please sign in the box above.</span><br/>
                <button type="button" onClick={clearSignature}>Clear Signature</button>
            </div>
            </div>      
            <div className="additional-details form-section">                  
                {RenderUploadText()}                    
            </div>  
            <div className="border-div"></div>
        </div>
        
        <div className="pdf-embed form-section">          
          <PdfEditor 
              formData={formData}               
              signatureData={sigCanvas.current && !sigCanvas.current.isEmpty() ? sigCanvas.current.getCanvas().toDataURL('image/png') : null}
              onPdfGenerated={setPdfBytes} 
              triggerPreview={triggerPreview}
              PreviousAddresses={prevAddresses}
              PreviousNames={prevNames}

          />
        </div>                

          <div className="form-group checkbox-group">
            <input type="checkbox" checked={termsAccepted} onChange={handleCheckboxChange} />
            <label>I declare that all information provided herein is true, complete, and correct to the best of my knowledge and belief.</label>
          </div>
          <button type="submit">Submit</button>
          {message && <p className="message success">{message}</p>}
          {error && <p className="message error">{error}</p>}
        </form>
      </div>      
    </>
    )}  
    </>
  );
};

export default LeadForm;
