import React, { useState } from 'react';
import axios from 'axios';
import './ClientLogin.css';
import { useNavigate } from 'react-router-dom';


const ClientLogin = () => {
    const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');    
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${axiosDomain}/api/auth/client-login`, { username, password });          
            const { data } = response;  
            if (!data.emailFound) {
                setError('Email address not found in database');
                return;
            } else if (!data.passwordMatch) {
                setError('Incorrect password for the specified email address');
                return;
            }
            const token = data.token;                        
            navigate(`/?tk=${token}`, {state: {password}});
        } catch (error) {
            console.log('error:', error);
            setError('Invalid login credentials');
        }
    };

    return (  
        <>
        <div className="cll-login-box">
            <a href="/team-login" className="team-login-link">ClaimLion Users Login</a>
        </div>
        <div className="login-container">            
            <div className="login-form-team">
                <div className="login-header">                
                <h2>Client Login</h2>
                </div>
                <form onSubmit={handleSubmit}>
                <label htmlFor="username">Username</label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />

                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />

                {error && <p className="error-message">{error}</p>}

                <button className="team-login-button" type="submit">Login</button>
                </form>
            </div>
        </div>   
        </>                             
    );
};

export default ClientLogin;
