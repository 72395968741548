// src/CallList.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Button,
    Typography, CircularProgress, Snackbar, Alert
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const ToCall = () => {
    const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
    const [callList, setCallList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        fetchCallList();
    }, []);

    const fetchCallList = async () => {
        try {
            const response = await axios.get(`${axiosDomain}/api/call-list`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('utltoken')}` // Adjust based on your auth setup
                }
            });
            setCallList(response.data.callList);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching call list:', err);
            setError('Failed to fetch call list.');
            setLoading(false);
        }
    };

    const markAsDone = async (email) => {
        try {
            await axios.post(`${axiosDomain}/api/update-call-status`, { email }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('utltoken')}` // Adjust based on your auth setup
                }
            });
            setCallList(prevList => prevList.filter(item => item.email !== email));
            setSuccessMessage(`Call status updated for ${email}`);
        } catch (err) {
            console.error('Error updating call status:', err);
            setError('Failed to update call status.');
        }
    };

    const handleCloseSnackbar = () => {
        setError('');
        setSuccessMessage('');
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div style={{ padding: '2rem' }}>
            <Typography variant="h4" gutterBottom>
                Pending Call List
            </Typography>
            {callList.length === 0 ? (
                <Typography variant="h6">No pending calls.</Typography>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Email</strong></TableCell>
                                <TableCell><strong>Full Name</strong></TableCell>
                                <TableCell><strong>Telephone / Mobile</strong></TableCell>
                                <TableCell><strong>Action</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {callList.map((item) => (
                                <TableRow key={item.email}>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>{`${item.first_name} ${item.last_name}`}</TableCell>
                                    <TableCell>{`${item.telephone || 'N/A'}, ${item.mobile || 'N/A'}`}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            startIcon={<CheckIcon />}
                                            onClick={() => markAsDone(item.email)}
                                        >
                                            Mark as Done
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Error Snackbar */}
            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>

            {/* Success Snackbar */}
            <Snackbar
                open={!!successMessage}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ToCall;
