import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';


const PdfEditor = ({ formData, signatureData, onPdfGenerated, triggerPreview, PreviousAddresses, PreviousNames}) => {
    const [pdfBytes, setPdfBytes] = useState(null);
    const [coaBytes, setCOABytes] = useState(null);
    const [appendixBytes, setAppendixBytes] = useState(null);
    const [dataUri, setDataUri] = useState('');
    const [templateBytes, setTemplateBytes] = useState(null);
    const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;


    useEffect(() => {
        const fetchPdfTemplate = async () => {
            try {
                const response = await axios.get(`${axiosDomain}/api/pdf/template`, {
                responseType: 'arraybuffer'
                });
                setPdfBytes(response.data);
            } catch (error) {
                console.error('Error fetching PDF template:', error);
            }
        };

        const fetchCOATemplate = async () => {
            try {
                const response = await axios.get(`${axiosDomain}/api/pdf/coa`, {
                responseType: 'arraybuffer'
                });
                setCOABytes(response.data);
            } catch (error) {
                console.error('Error fetching COA template:', error);
            }
        };

        const fetchAppendixTemplate = async () => {
            try {
                const response = await axios.get(`${axiosDomain}/api/pdf/appendix`, {
                responseType: 'arraybuffer'
                });
                setAppendixBytes(response.data);
            } catch (error) {
                console.error('Error fetching Appendix template:', error);
            }
        };
  
        fetchPdfTemplate();
        fetchCOATemplate();
        fetchAppendixTemplate();

    }, []);
  
  useEffect(() => {
    const fillPdf = async () => {
      if (!pdfBytes) {        
        return;
      }

      const mergedDoc = await PDFDocument.create();
      let pdfDocs = [];
      if (formData.addlenders) {        
        for (const [index, lender] of formData.addlenders.entries()) {
            pdfDocs.push({
                lender: lender.lender,                                
                caseref: formData.addcases[index],
                data: await PDFDocument.load(pdfBytes)
                }             
            );
            const pdfDoc = pdfDocs[pdfDocs.length - 1].data;            
            const form = pdfDoc.getForm();        
            form.getTextField('LenderName1').setText(lender.lender);    
            form.getTextField('AccountHolder1').setText(formData.title + ' ' + formData.first_name + ' ' + formData.last_name);                        
            //form.getTextField('DOB').setText(formData.date_of_birth);                        
            form.getTextField('DOB1').setText(new Date(formData.date_of_birth).toLocaleDateString());            
            form.getTextField('AddLine1_2_1').setText(formData.current_address_line_1);
            form.getTextField('AddLine2_2_1').setText(formData.current_address_line_2);
            form.getTextField('AddLine3_2_1').setText(formData.current_address_line_3);
            form.getTextField('AddLine4_2_1').setText(formData.current_address_line_4);
            form.getTextField('PostCode_2_1_1').setText(formData.current_post_code);
            form.getTextField('OurRef_1').setText(formData.addcases[index]);
            if (PreviousAddresses.length > 0) {
                form.getTextField('PrevAddLine1_1').setText(PreviousAddresses[0].previous_address_line_1);
                form.getTextField('PrevAddLine2_1').setText(PreviousAddresses[0].previous_address_line_2);
                form.getTextField('PrevAddLine3_1').setText(PreviousAddresses[0].previous_address_line_3);
                form.getTextField('PrevAddLine4_1').setText(PreviousAddresses[0].previous_address_line_4);
                form.getTextField('PrevPostCode_1').setText(PreviousAddresses[0].previous_post_code);
            }

            if (PreviousNames.length > 0) {
                form.getTextField('PreviousName').setText(PreviousNames[0]);
            }

            //form.getTextField('LoanNum').setText(formData.addcredits[index].credit_agreement_number || '');            
            //form.getTextField('LoanNum').setText(formData.credit_agreement_number);                              
            form.getTextField('LOADate1').setText(new Date().toLocaleDateString());
            if (signatureData) {
                const pngImage = await pdfDoc.embedPng(signatureData);
                const pages = pdfDoc.getPages();
                const firstPage = pages[0];
                firstPage.drawImage(pngImage, {
                x: 90,
                y: 250,
                width: 100,
                height: 50
                });
            }
            
            const pdfBytesUpdated = await pdfDoc.save();
            const donorPDF = await PDFDocument.load(pdfBytesUpdated);
            const copiedPages = await mergedDoc.copyPages(donorPDF, donorPDF.getPageIndices());
            copiedPages.forEach(page => mergedDoc.addPage(page));            
            pdfDocs[pdfDocs.length - 1].data = pdfBytesUpdated;            
        }      
        
        let COADocs = [];
        
        if (coaBytes) {
            for (const [index, lender] of formData.addlenders.entries()) {
                COADocs.push({
                    lender: lender.lender,
                    caseref: formData.addcases[index],
                    data: await PDFDocument.load(coaBytes)
                    }             
                );
                
                
                const COADoc = COADocs[COADocs.length - 1].data;                
                const COAform = COADoc.getForm(); 
                COAform.getTextField('datecreated').setText(new Date().toLocaleDateString());
                COAform.getTextField('clientfullname').setText(formData.title + ' ' + formData.first_name + ' ' + formData.last_name);
                COAform.getTextField('docsigneddate').setText(new Date().toLocaleDateString());                                        
                COAform.getTextField('refnumber').setText(formData.addcases[index]);
                COAform.getTextField('ipaddress').setText(formData.ip);                
        
                if (signatureData) {
                    const pngImage = await COADoc.embedPng(signatureData);
                    const pages = COADoc.getPages();
                    const firstPage = pages[0];
                    firstPage.drawImage(pngImage, {
                    x: 220,
                    y: 450,
                    width: 100,
                    height: 50
                    });
                }
                const coaBytesUpdated = await COADoc.save();
                const COAPDF = await PDFDocument.load(coaBytesUpdated);
                const copiedPagesCOA = await mergedDoc.copyPages(COAPDF, COAPDF.getPageIndices());
                copiedPagesCOA.forEach(page => mergedDoc.addPage(page));
                COADocs[COADocs.length - 1].data = coaBytesUpdated;            
            }
        }
        
        let appendixDocs = [];
        if (appendixBytes) {
            if (PreviousNames.length > 1 || PreviousAddresses.length > 1) {
                appendixDocs.push({
                    data: await PDFDocument.load(appendixBytes)
                    }
                );
                                
                const appendixDoc = await PDFDocument.load(appendixBytes);
                const appendixForm = appendixDoc.getForm();
                PreviousNames.forEach((name, index) => {
                    if (index + 1 <= 3) {
                        appendixForm.getTextField(`PrevName${index+1}`).setText(name);
                    }
                });

                PreviousAddresses.forEach((address, index) => {
                    if (index + 1 <= 5) {
                        appendixForm.getTextField(`PrevAdd${index+1}Line1`).setText(address.previous_address_line_1);
                        appendixForm.getTextField(`PrevAdd${index+1}Line2`).setText(address.previous_address_line_2);
                        appendixForm.getTextField(`PrevAdd${index+1}Line3`).setText(address.previous_address_line_3);
                        appendixForm.getTextField(`PrevAdd${index+1}Line4`).setText(address.previous_address_line_4);
                        appendixForm.getTextField(`PrevAdd${index+1}Line5`).setText(address.previous_post_code);
                    }
                });
                if (signatureData) {
                    const pngImage = await appendixDoc.embedPng(signatureData);
                    const pages = appendixDoc.getPages();
                    const firstPage = pages[0];
                    firstPage.drawImage(pngImage, {
                    x: 90,
                    y: 90,
                    width: 100,
                    height: 50
                    });
                }
                appendixForm.getTextField('LOADate').setText(new Date().toLocaleDateString());
                const appendixBytesUpdated = await appendixDoc.save();
                const appendixPDF = await PDFDocument.load(appendixBytesUpdated);
                const copiedPagesAppendix = await mergedDoc.copyPages(appendixPDF, appendixPDF.getPageIndices());
                copiedPagesAppendix.forEach(page => mergedDoc.addPage(page));    
                appendixDocs[appendixDocs.length - 1].data = appendixBytesUpdated;        
            }
        }
        
        const mergedPdfBytes = await mergedDoc.save();
        //onPdfGenerated(mergedPdfBytes);
               
        onPdfGenerated({
            loaPDFs: pdfDocs.map(pdf => ({
                lender: pdf.lender,
                caseRef: pdf.caseref,
                data: pdf.data
            })),
            coaPDFs: COADocs.map(pdf => ({
                lender: pdf.lender,
                caseRef: pdf.caseref,
                data: pdf.data
            })),
            appendixPDFs: appendixDocs.length > 0 ? appendixDocs.map(doc => ({                
                data: doc.data
            })) : []
            }
        );

        const newUri = await mergedDoc.saveAsBase64({ dataUri: true });
        setDataUri(newUri);        
      }            
    };
    if (pdfBytes && (triggerPreview || formData || signatureData)) {
        fillPdf();
    }
    //fillPdf();
    

  }, [pdfBytes, coaBytes, appendixBytes, formData, signatureData, onPdfGenerated, triggerPreview]); 

  
    // return (
    //   <div>
    //     {dataUri && <iframe src={dataUri} width="75%" height="1200px" style={{ border: 'none' }} className="pdfframe"></iframe>}
    //   </div>
    // );

   
  
};



export default PdfEditor;
