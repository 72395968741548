import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import NotFound from './components/NotFound';

import TokenVerification from './components/TokenVerification';
import Analytics from './components/Analytics';
import ThankYou from './components/ThankYou';
import TeamLogin from './components/cllteam/login';
import ClientLogin from './components/ClientLogin';
import Dashboard from './components/cllteam/dashboard'
import PendingCases from './components/cllteam/PendingCases';
import ReviewedCases from './components/cllteam/ReviewedCases';
import ClosedCases from './components/cllteam/ClosedCases';
import ToCall from './components/cllteam/toCall';
import ClientSearch from './components/cllteam/ClientSearch';
import RejectionReasons from './components/cllteam/RejectionReasons';
import Register from './components/cllteam/Register';
import Header from './components/header';
import Navbar from './components/cllteam/navbar';
import VideoPlayer from './components/UTLVideo';

const ApplicationRoutes = () => {
  const { isAuthenticated, limitAccess } = useAuth();

  if (isAuthenticated) {        
    return (
      <>
      <Navbar />
      <Routes>        
        <Route path="/" element={<TokenVerification />} />        
        <Route path="/to-call" element={<ToCall />}/>
        <Route path="/push-email" element={<ClientSearch />}/>
        {limitAccess === false ? (
          <>
          <Route path="/dashboard" element={<Dashboard />}/>
          <Route path="/pending-cases" element={<PendingCases />}/>          
          <Route path="/reviewed-cases" element={<ReviewedCases />}/>
          <Route path="/closed-cases" element={<ClosedCases />}/>
          <Route path="/rejection-reasons" element={<RejectionReasons />}/>        
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/explainer-video" element={<VideoPlayer />} />   
          <Route path="*" element={<Analytics />}/>                              
          </>
        ) : (
          <Route path="*" element={<ClientSearch />}/>                              
        )}
      </Routes>
      </>
    )
  } else {
    return (
      <Routes>
        <Route path="/" element={<TokenVerification />} />        
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/login" element={<ClientLogin />} />
        <Route path="/dashboard" element={<TeamLogin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/team-login" element={<TeamLogin />} />  
        <Route path="/explainer-video" element={<VideoPlayer />} />   
        <Route path="*" element={<ClientLogin />} />
        
      </Routes>
    )
  }  
}


const App = () => {
  return (    
    <>
      <Header />      
      <div className="App">
        <AuthProvider>
          <Router>
            <ApplicationRoutes />
          </Router>
        </AuthProvider>        
      </div>    
    </>
  );
};

export default App;
