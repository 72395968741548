import React from 'react';
import './Loader.css'; // Add your CSS styles here

function Loader() {
    return (
        <>
        {/* <header className="form-header">
            <img src="/clllogo.png" alt="Logo" className="logo" />
        </header> */}
        <div className="loader-container">
            <div className="loader"></div>
        </div>
        </>
    );
}

export default Loader;