import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import LeadForm from './LeadForm';
import ResubmissionForm from './ResubmissionForm';
import './LeadForm.css';
import './UTLVideo.css'; // Optional: For styling

const VideoPlayer = () => {
  return (
    <div className="video-player-container">
      <h2>Watch this guide to help you through the submission process</h2>
      <video width="800" controls>
        <source src="/utl_video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};


const TokenVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const token = query.get('tk');
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  const [tokenValid, setTokenValid] = useState(null);
  const [passwordVerified, setPasswordVerified] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [userData, setUserData] = useState(null);
  const [hasRejectedDocuments, setHasRejectedDocuments] = useState(false);
  const [rejectedDocuments, setRejectedDocuments] = useState([]);

  const state = location.state;
  const initialPassword = state?.password || '';

  useEffect(() => {
    if (token) {
      const verifyToken = async () => {
        try {
          const response = await axios.get(`${axiosDomain}/api/auth/verifyToken`, { params: { token } });
          if (response.data.tokenStatus === 'Already submitted') {
            setTokenValid(false);
            setError('You have already submitted your information.');
          } else if (response.data.tokenStatus === 'Token verified successfully') {
            if (response.data.caseStatus === 'resubmission') {
              setTokenValid(true);
              setHasRejectedDocuments(true);
              setError('');
            } else {
              setTokenValid(true);
              setHasRejectedDocuments(false);
              setError('');
            }
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          setTokenValid(false);
          setError(error.response?.data?.message || 'Error verifying token.');
        }
      };
      verifyToken();
    } else {
      navigate('/login');
    }
  }, [token, axiosDomain, navigate]);

  useEffect(() => {
    if (tokenValid && initialPassword && !passwordVerified) {
      const verifyPassword = async () => {
        try {
          const response = await axios.post(`${axiosDomain}/api/auth/verifyPassword`, { token, password: initialPassword });
          setPasswordVerified(true);
          const udata = response.data;
          const response2 = await axios.get(`${axiosDomain}/api/get-ip`);
          const ip = response2.data.ip;

          if (hasRejectedDocuments) {
            const response3 = await axios.post(`${axiosDomain}/api/auth/checkRejection`, { case_id: udata.case_id });            
            if (response3.data === 'NoActiveRejection') {              
              setHasRejectedDocuments(false);
              setTokenValid(false);
              setError('You have already submitted your information.');
            } else {
              udata.rejectedDocuments = response3.data;
            }
          }

          setUserData({ ...udata, ip: ip });
          setError('');
        } catch (error) {
          console.error('Error verifying password:', error);
          setError('Invalid password.');
        }
      };
      verifyPassword();
    }
  }, [token, tokenValid, initialPassword, passwordVerified, axiosDomain, hasRejectedDocuments]);

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${axiosDomain}/api/auth/verifyPassword`, { token, password });
      setPasswordVerified(true);
      const udata = response.data;
      const response2 = await axios.get(`${axiosDomain}/api/get-ip`);
      const ip = response2.data.ip;

      if (hasRejectedDocuments) {
        const response3 = await axios.post(`${axiosDomain}/api/auth/checkRejection`, { case_id: udata.case_id });        
        if (response3.data === 'NoActiveRejection') {
          setHasRejectedDocuments(false);
          setTokenValid(false);
          setError('You have already submitted your information.');
        } else {
          udata.rejectedDocuments = response3.data;
        }
      }

      setUserData({ ...udata, ip: ip });
      setError('');
    } catch (error) {
      console.error('Error verifying password:', error);
      setError('Invalid password');
    }
  };

  if (!token) {
    return (
      <div className="login-form">
        <h1>You are not authorized to view this page.</h1>
      </div>
    );
  }

  if (tokenValid === null) return <div>Loading...</div>;

  if (tokenValid === false) {
    return (
      <div className="login-form">
        {error}
      </div>
    );
  }

  if (passwordVerified) {
    if (hasRejectedDocuments) {
      return <ResubmissionForm userData={userData} />;
    } else {
      return <LeadForm userData={userData} />;
    }
  }

  return (
    <div className="login-form">
      <h1>Enter Password / PIN</h1>
      <form className="loginpage-form" onSubmit={handlePasswordSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          placeholder="Password / PIN"
        />
        <button type="submit">Submit</button>
        {error && <p className="error">{error}</p>}
      </form>
      <VideoPlayer />
    </div>
  );
};

export default TokenVerification;
