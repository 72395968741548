import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './processedDocuments.css'; 

const ProcessedDocuments = ({ caseId }) => {
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProcessedDocuments = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${axiosDomain}/api/case-processed-documents`,
          { case_id: caseId },
          { headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` } }
        );
        setDocuments(response.data.documents);
      } catch (err) {
        console.error("Error fetching processed documents:", err);
        setError('Failed to load processed documents. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProcessedDocuments();
  }, [axiosDomain, caseId]);
  
  const downloadDocument = async (docId, fileName) => {
    try {
      const response = await axios.get(`${axiosDomain}/api/download-document`, {
        params: { docId: docId },
        headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` },
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading document:', error);
    }
  };

  if (isLoading || error) {
    return null; 
  }

  if (!documents || documents.length === 0) {
    return null; 
  }

  return (
    <div className="processed-documents-section">
      <h3>Processed Documents</h3>
      {documents.map((doc, idx) => (
        <React.Fragment key={`processed-document-fragment-${idx}`}>
          <div className="document-row processed">
            <div className="document-embed">
              {doc.fileContent ? (
                doc.fileName.endsWith('.pdf') ? (
                  <iframe
                    title={`Processed-Frame-${doc.id}`}
                    src={`data:application/pdf;base64,${doc.fileContent}`}
                    width="100%"
                    height="500px"
                  />
                ) : doc.fileName.match(/\.(jpg|jpeg|png)$/) ? (
                  <img
                    src={`data:image/${doc.fileName.split('.').pop()};base64,${doc.fileContent}`}
                    alt={doc.documenttype}
                    style={{ maxWidth: '100%', maxHeight: '500px' }}
                  />
                ) : (
                  <p>Cannot display this document type.</p>
                )
              ) : (
                <p>No content available.</p>
              )}              
              <button
                onClick={() => downloadDocument(doc.id, doc.fileName)}
                className="download-button"
              >
                Download
              </button>
            </div>
            <div className="document-info">
              <p>
                <strong>Document Type:</strong> {doc.fileType}
              </p>
              <p>
                <strong>Status:</strong> {doc.fileStatus === 'rejected-closed' ? 'Rejected' : doc.fileStatus}
              </p>
              <div className="status-icon">
                {doc.fileStatus === 'accepted' && <span className="checkmark">&#x2714;</span>}
                {(doc.fileStatus === 'rejected' || doc.fileStatus === 'rejected-closed') && (
                  <span className="cross">&#x2716;</span>
                )}
              </div>
            </div>
          </div>
          <hr className="document-divider1" />
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProcessedDocuments;
