import React from 'react';
import './UTLVideo.css'; // Optional: For styling

const VideoPlayer = () => {
  return (
    <div className="video-player-container">
      <h2>Watch this guide to help you through the submission process</h2>
      <video width="800" controls>
        <source src="/utl_video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;